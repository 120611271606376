<template>
  <dialog-schedule key="create-dialog-schedule-quotation" :common-dialog="dialog" :dialog-width="dialogWidth" >
    <template v-slot:title> Generate Schedule </template>
    <template v-slot:body>
      <v-row class="mx-0">
        <v-col md="12" class="py-0" :class="{ 'prevent-click': formLoading }">
          <recurring-schedule :key="`contract-recurring-schedule-${schedule_key}`" />
        </v-col>
        <v-col md="12" class="py-4">
          <p class="my-0 red--text font-weight-600">Note: Upon saving, {{contractScheduleLength}} unassigned visits will be generated for this contract, and the contract will be marked as active.</p>
        </v-col>
      </v-row>
    </template>
    <template v-slot:action>
      <v-btn depressed :disabled="formLoading" class="mx-2 custom-grey-border custom-bold-button" v-on:click="$emit('close', true)">
        Cancel
      </v-btn>
      <v-btn depressed :disabled="formLoading" :loading="formLoading" class="mx-2 custom-bold-button white--text" v-on:click="saveSchedule()" color="cyan">
        Save
      </v-btn>
    </template>
  </dialog-schedule>
</template>

<script>
import { mapGetters } from "vuex";
import Dialog from "@/view/pages/partials/Dialog";
import RecurringSchedule from "@/view/pages/quotation/Contract/RecurringSchedule.vue";
import { POST } from "@/core/services/store/request.module";

export default {
  name: "contract-create-recurring-schedule",
  data() {
    return {
      schedule_key: Number(new Date()),
      formLoading: false,
    };
  },
  props: {
    dialog: {
      type: Boolean,
      default: false,
    },
    quotationId: {
      type: Number,
      default: 0,
    }
  },
  methods: {
    saveSchedule() {
      this.formLoading = true;
      this.$store.dispatch(POST, {
        url: `quotation-v1/${this.quotationId}/schedule`,
        data: {
          schedule: this.contractRecurringSchedule,
          dates: this.contractScheduleOutput,
        }
      }).then(() => {
        this.$emit('success', true);
      }).catch(error => {
        console.log(error, "error");
      }).finally(() => {
        this.formLoading = false;
      });
    }
  },
  components: {
    "dialog-schedule": Dialog,
    "recurring-schedule": RecurringSchedule,
  },
  mounted() { },
  computed: {    
    ...mapGetters(['contractScheduleOutput', 'contractRecurringSchedule']),
    contractScheduleLength() {
      return this.contractScheduleOutput.filter(row => Number(row.status) === 1).length;
    },
    dialogWidth() {
      return this.lodash.toSafeInteger((document.body.clientWidth / 100) * 50);
    },
  },
};
</script>
